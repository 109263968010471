<template>
  <div>
    <project-list :customerInfo="this.customerInfo" :addflag="false" :delflag="false" :pmStatus="3"></project-list>
  </div>
</template>

<script>
import PM from '../../api/base/api_pm';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import ProjectList from "./list";

export default {
  name: "proverifying", // 待验证项目
  components: {
    ProjectList
  },
  data() {
    return {
      customerInfo: {}
    }
  },
  // 初始化加载
  mounted() {
      this.init();
  },
  // 方法定义
  methods: {
    init() {

    }
  }
}
</script>